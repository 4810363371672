import "./App.css";
import startercar from "./images/startercar.PNG";

function App() {
  return (
    <div className="2m24">
      <header className="App-header">
        <img src={startercar} className="App-logo" alt="main logo"/>
        <h1 className="title">2M24</h1>
        <h2 className="subtitle">coming soon</h2>
      </header>
    </div>
  );
}

export default App;
